import React from "react"

import { configureStore } from "@reduxjs/toolkit"

import allReducers from "./src/stores/reducers"
import { Provider } from "react-redux"

import "./src/styles/reset.css"
import "./node_modules/bootstrap/dist/css/bootstrap-grid.min.css"
import "react-18-image-lightbox/style.css"
import "react-datepicker/dist/react-datepicker.css"

import "./src/styles/base.less"

const isBrowser = typeof window !== "undefined"

const store = configureStore(allReducers)

const WrapWithProviders = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

export default WrapWithProviders
