import resultsReducer from "./ResultsStore/reducer"
import profileReducer from "./ProfileStore/reducer"

const allReducers = {
  reducer: {
    results: resultsReducer,
    profile: profileReducer,
  },
}

export default allReducers
